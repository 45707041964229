import React from 'react';
import { TScene } from '@kerfed/types';

import FileUploader from '../FileUploader';
import File from '../File';

import { getUUIDFromFileId } from '@kerfed/common/utils';

interface FileListProps {
  models?: TScene[];
  loadingCount?: number;
  onAddOrderFile?: (uploadIds: string[]) => Promise<void>;
}

const FileList = ({
  models = [],
  loadingCount = 0,
  onAddOrderFile,
}: FileListProps) => {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-lg-3">
      {models.map((model, index) => (
        <div className="col p-2" key={index}>
          <File
            fileId={getUUIDFromFileId(model?.original?.file_id || '')}
            fileName={model?.original?.name || ''}
            fileUrl={model?.preview?.url || ''}
          />
        </div>
      ))}

      {!!loadingCount &&
        [...Array(loadingCount)].map((_, index) => (
          <div className="col p-2" key={index}>
            <File loading={true} />
          </div>
        ))}

      {!loadingCount && (
        <div className="col p-2">
          <div className="card dropzone h-100 d-flex justify-content-center">
            <FileUploader onUploads={onAddOrderFile} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileList;
