import React from 'react';

import { Message, UserInfo, KeyEditor } from '../components';

import { useFirebaseAuth } from '../hooks/useFirebaseAuth';

import 'bootstrap/js/dist/dropdown';

export default () => {
  const user = useFirebaseAuth();
  const userId = user?.uid;

  if (!user) {
    return (
      <div className="loader ">
        <div className="spinner-border " role="status" />
      </div>
    );
  }

  return (
    <div className="container-md p-3 bg-white">
      <h1>My Account</h1>
      <hr />

      {userId ? (
        <>
          <UserInfo userId={userId} />
          <hr />
          <div>
            <h2>API Keys</h2>
            <p>
              Generate and manage API keys which allow you to use our
              <a href="https://api.kerfed.com"> REST API</a> to do anything your
              account is allowed to do, including getting quotes and orders,
              analyzing parts, or creating and editing a new Shop for custom
              pricing. Note that the full API key is only available immediately
              after creation for security; delete it and create a new one if you
              lose it.
            </p>
            <KeyEditor userId={userId} />
          </div>
        </>
      ) : (
        Message('Log in to see account information.', 'Not Logged In')
      )}

      <div className="segmentRectangle" />
    </div>
  );
};
