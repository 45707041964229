import React from 'react';

export default () => (
  <div>
    <div className="onboard">
      <img className="img-fluid w-25" src="/static/images/cube.svg" />
      <h2>Modern e-commerce for manufacturers.</h2>
    </div>
    <div className="container-lg p-3">
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <stripe-pricing-table
              pricing-table-id="prctbl_1MxcPtASUODFrkzExkb8HutJ"
              publishable-key="pk_test_6u8yjIjbcYtLKmqr2bEy6Irf"
            ></stripe-pricing-table>
          </div>
        </div>
      </div>
    </div>{' '}
  </div>
);
