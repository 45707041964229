import React, { ChangeEvent } from 'react';

interface NoteSectionProps {
  note?: string;
  onNoteChange(note?: string): void;
}

const NoteSection = ({ note, onNoteChange }: NoteSectionProps) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onNoteChange(event.target.value);
  };

  return (
    <div className="form-group">
      <textarea
        className="form-control rounded-0"
        value={note}
        onChange={handleChange}
        placeholder="Specify additional fabrication instructions."
        rows={3}
      />
    </div>
  );
};

export default NoteSection;
