import React from 'react';

interface LoginButtonProps {
  onLogin?: () => void;
}

const LoginButton = ({ onLogin }: LoginButtonProps) => {
  return (
    <img
      className="login-button"
      title="Click here to log in."
      src="/static/images/user.svg"
      onClick={onLogin}
    />
  );
};

export default LoginButton;
