import React, { useMemo } from 'react';
import { TScene } from '@kerfed/types';

import FileImage from 'app/src/components/FileImage';

interface GeometryImageProps {
  geomId?: string | null;
  models?: TScene[];
}

const GeometryImage = ({ geomId, models }: GeometryImageProps) => {
  const geometryImageUrl = useMemo(() => {
    if (!models?.length || !geomId) return '';

    let imageUrl = '';
    for (const model of models) {
      if (!model?.geometries?.length) continue;

      for (const geom of model.geometries) {
        if (geom.geom_id === geomId) {
          imageUrl = geom.preview?.url || '';
          break;
        }
      }
      if (imageUrl) break;
    }

    return imageUrl;
  }, [models, geomId]);

  if (!geometryImageUrl) return null;

  return <FileImage url={geometryImageUrl} />;
};

export default GeometryImage;
