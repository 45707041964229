export { default as Home } from './Home';
export { default as NotFound } from './NotFound';
export { default as Tutorial } from './Tutorial';
export { default as Onboard } from './Onboard';
export { default as OrderList } from './OrderList';
export { default as OrderEdit } from './OrderEdit';
export { default as QuoteList } from './QuoteList';
export { default as QuoteEdit } from './QuoteEdit';
export { default as QuoteNew } from './QuoteNew';
export { default as Materials } from './Materials';
export { default as ShopAdmin } from './ShopAdmin';
export { default as Account } from './Account';

export {
  About,
  Returns,
  Terms,
  Technology,
  Tolerances,
  Design,
  Pricing,
  Privacy,
} from './Article';
