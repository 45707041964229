import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { titleCase } from '@kerfed/common/utils';
import { PartConfiguration, Shop } from '@kerfed/client';

import EditableProcessOption, { TOptions } from '../EditableProcessOption';
import QuantityPicker from '../QuantityPicker';

import { MAP_METHOD } from 'app/src/constants';

interface EditablePartConfigsProps {
  shop: Shop;
  config?: PartConfiguration | null;
  onChangePartOrderData: (newConfig: PartConfiguration) => void;
}

export const EditablePartConfigs = ({
  shop,
  config,
  onChangePartOrderData,
}: EditablePartConfigsProps) => {
  const [quantity, setQuantity] = useState(0);
  const [thicknessOptions, setThicknessOptions] = useState<TOptions[]>([]);
  const [finishOptions, setFinishOptions] = useState<TOptions[]>([]);
  const [colorOptions, setColorOptions] = useState<TOptions[]>([]);

  const materialOptions = useMemo(() => {
    const methodId = config?.method_id;
    if (!shop?.shop_id || !methodId) return [];

    const materialKeys: string[] =
      shop?.combinations?.['method_id']?.[methodId]?.material_id || [];

    console.log({ config, materialKeys });
    const materials = shop?.descriptions?.['material_id'] || {};
    const options: TOptions[] = [];

    for (const key of materialKeys) {
      options.push({
        label: materials[key]?.label || titleCase(key),
        value: key,
      });
    }

    return options;
  }, [shop, config?.method_id]);

  const handleChangeOrderData = (
    key: keyof PartConfiguration,
    data: string,
  ) => {
    if (!config) return;

    onChangePartOrderData({
      ...config,
      [key]: data,
    });
  };

  const handleChangeMaterial = (value: string) => {
    handleChangeOrderData('material_id', value);
  };

  const handleChangeThickness = (value: string) => {
    handleChangeOrderData('thickness_id', value);
  };

  const handleChangeFinish = (value: string) => {
    handleChangeOrderData('finish_id', value);
  };

  const handleChangeColor = (value: string) => {
    handleChangeOrderData('color_id', value);
  };

  const debounceChangeQuantity = useCallback(
    debounce((newQuantity: number) => {
      onChangePartOrderData({
        ...config,
        quantity: newQuantity,
      });
    }, 500),
    [onChangePartOrderData],
  );

  const handleChangeQuantity = (newQuantity: number) => {
    if (!config) return;
    setQuantity(newQuantity);
    debounceChangeQuantity(newQuantity);
  };

  useEffect(() => {
    setQuantity(config?.quantity || 0);
  }, [config?.quantity]);

  useEffect(() => {
    if (shop?.shop_id && config?.material_id) {
      const materialId = config.material_id;
      const materialThickness =
        shop?.combinations?.['material_id']?.[materialId]?.thickness_id;
      const materialFinish =
        shop?.combinations?.['material_id']?.[materialId]?.finish_id;

      const tmpThinkessOptions: TOptions[] = [];
      const thicknessValue = shop.descriptions?.['thickness_id'] || {};
      if (materialThickness) {
        for (const key of materialThickness) {
          tmpThinkessOptions.push({
            label: thicknessValue[key]?.label || titleCase(key),
            value: key,
          });
        }
        setThicknessOptions(tmpThinkessOptions);
      }

      const tmpFinishOptions: TOptions[] = [];
      const finishValue = shop.descriptions?.['finish_id'] || {};
      if (materialFinish) {
        for (const key of materialFinish) {
          tmpFinishOptions.push({
            label: finishValue[key]?.label || titleCase(key),
            value: key,
          });
        }
        setFinishOptions(tmpFinishOptions);
      }
    }
  }, [shop, config?.material_id]);

  useEffect(() => {
    if (shop?.shop_id && config?.finish_id) {
      const finishId = config.finish_id;
      const finishColor =
        shop?.combinations?.['finish_id']?.[finishId]?.color_id;
      const colorValue = shop.descriptions?.['color_id'] || {};
      const tmpColorOptions: TOptions[] = [];

      if (finishColor) {
        for (const key of finishColor) {
          tmpColorOptions.push({
            label: colorValue[key]?.label || titleCase(key),
            hex: colorValue[key]?.hex || '',
            value: key,
          });
        }
        setColorOptions(tmpColorOptions);
      }
    }
  }, [shop, config?.finish_id]);

  return (
    <>
      <p>Configure Quantity</p>
      <QuantityPicker quantity={quantity} onChange={handleChangeQuantity} />

      <EditableProcessOption
        label="Material"
        options={materialOptions}
        selected={config?.material_id}
        onChange={handleChangeMaterial}
      />

      {!!thicknessOptions.length && (
        <EditableProcessOption
          label="Thickness"
          options={thicknessOptions}
          selected={config?.thickness_id}
          onChange={handleChangeThickness}
        />
      )}

      {!!finishOptions.length && (
        <EditableProcessOption
          label="Finish"
          options={finishOptions}
          selected={config?.finish_id}
          onChange={handleChangeFinish}
        />
      )}

      {!!colorOptions.length && (
        <EditableProcessOption
          label="Color"
          options={colorOptions}
          selected={config?.color_id}
          onChange={handleChangeColor}
        />
      )}
    </>
  );
};

export default EditablePartConfigs;
