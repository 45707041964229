import React from 'react';
import JSONEditor from '../components/JSONEditor';
import { QuoteList, OrderList } from '../components';
import { useShopEditQuery } from '../query/queries';
import { FormattedId } from '../components/formatting';

import { useShopEditMutation } from '../query/mutations';

type Props = {
  userId?: string;
  user?: User;
  shopId?: string;
};

type EditProps = {
  shopId: string;
};

const EditWidget = ({ shopId }: EditProps) => {
  const shopQuery = useShopEditQuery(shopId);
  const shop = shopQuery.data;
  const [createShopMutation] = useShopEditMutation(shopId);
  const onSave = async (shopContent: any) => {
    return await createShopMutation({ shopContent });
  };

  if (shopQuery.isLoading) {
    return (
      <div data-iframe-height={true} className="align-center loader">
        <div className="spinner-border" role="status" />
        <h3>Loading Shop Information</h3>
      </div>
    );
  } else if (shopQuery.status === 'error') {
    return Message('danger', shopQuery.error.message, 'Error');
  }

  return <JSONEditor object={shop} onSave={onSave} />;
};

type ShopEditProps = {
  userId: string;
  shopId: string;
};
const Message = (kind: string, body: string, heading?: string) => (
  <div className={`alert alert-${kind} rounded-0`} role="alert">
    {heading ? <h2 className="alert-heading">{heading}</h2> : null}
    {body ? <p>{body}</p> : null}
  </div>
);

export const ShopEdit = ({ userId, shopId }: ShopEditProps) => {
  return (
    <div className="container-md p-3 bg-white">
      <h1>Shop Admin Console</h1>
      <h5>
        <b>shopId:</b> {shopId}
      </h5>
      <h5>
        <b>userId:</b> <FormattedId id={userId} />
      </h5>
      <hr />

      <div>
        <h2>Recent Quotes</h2>
        <QuoteList shopId={shopId} />
      </div>
      <hr />
      <div>
        <h2>Recent Orders</h2>
        <OrderList shopId={shopId} />
      </div>
      <hr />

      <div>
        <h2> Edit Shop </h2>
        <EditWidget shopId={shopId} />
      </div>

      <div className="segmentRectangle" />
    </div>
  );
};

export default ShopEdit;
