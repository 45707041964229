import React from 'react';

interface QuantityPickerProps {
  quantity?: number;
  onChange: (newQuantity: number) => void;
}

export const QuantityPicker = ({
  quantity = 0,
  onChange,
}: QuantityPickerProps) => {
  const handleReduceQuantity = () => {
    onChange(Math.max(0, quantity - 1));
  };

  const handleIncreaseQuantity = () => {
    onChange(quantity + 1);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantityString = e.target.value;
    // An empty string is interpreted as zero.
    if (!newQuantityString) return onChange(0);

    // Ignore changes that would make this field non-numeric.
    const newQuantity = Number.parseInt(newQuantityString, 10);
    if (!isFinite(newQuantity)) return;

    // Report any valid quantity change.
    onChange(newQuantity);
  };

  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <button
          className="btn btn-outline-secondary"
          type="button"
          disabled={quantity <= 0}
          onClick={handleReduceQuantity}
        >
          -
        </button>
      </div>

      <input
        type="text"
        className="form-control quantity-input"
        aria-describedby="basic-addon2"
        style={{ maxWidth: '4em' }}
        onChange={handleChangeInput}
        value={quantity}
      />

      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={handleIncreaseQuantity}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default QuantityPicker;
