import { EmbedContext } from '../contexts';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ErrorBoundary, QuoteUploader, ShopInfo } from '../components';

export default (props: any) => {
  // extract the parameters from the current URL
  const shopId = props?.shopId ?? 'kerfed';
  // are we embedded in an iFrame
  const { isEmbedded } = useContext(EmbedContext);

  return (
    <div
      className={
        isEmbedded
          ? 'container-fluid bg-white py-3'
          : 'container-fluid bg-white '
      }
      data-iframe-height="true"
    >
      {isEmbedded ? (
        <QuoteUploader shopId={shopId} />
      ) : (
        <div className="grid">
          <div className="row">
            <div className="col-lg-4 hidden-lg-down new-leftpanel" />
            <div className="col-md-5 m-5">
              <div className="grid">
                <ErrorBoundary>
                  <ShopInfo shopId={shopId} />
                </ErrorBoundary>

                <div className="row py-3">
                  <div className="col-md">
                    <h1>New Quote</h1>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-md-9">
                    <h2>Upload Your Assembly</h2>
                    <p>
                      Upload a single part, or a full assembly with multiple
                      parts from either 2D or 3D CAD. We'll do the analysis and
                      get you a{' '}
                      {shopId == 'kerfed' && (
                        <Link to="/pricing">competitive</Link>
                      )}{' '}
                      quote on one (or hundreds) of parts in under a minute.
                    </p>
                  </div>
                </div>
                <div className="row my-3 NonSelectable">
                  <div className="col-md">
                    <QuoteUploader shopId={shopId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
