import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import EditableAddressBlock from './EditableAddressBlock';

import { Address } from '../../../common/src/schemas/fields';

type Props = {
  orderId: string;
};

export default ({ orderId }: Props) => {
  const dispatch = useDispatch();

  // global billing in redux
  const billing = useSelector((state) => getBilling(state, { orderId }));
  const onUpdateBilling = (newBilling) =>
    dispatch(billingUpdate({ orderId, billing: newBilling }));
  if (!billing) {
    // set default values here
    onUpdateBilling({ address_same_as_shipping: true });
  }

  // disable input if we're ordering
  const disabled = useSelector((state) => getIsOrdering(state, { orderId }));

  // called when a field like `name` is updated
  const onFieldChange = (event: any): void => {
    const { name, value } = event.target;
    onUpdateBilling({
      ...billing,
      address: {
        ...billing?.address,
        [name]: value,
      },
    });
  };

  // called when a geosuggested address is confirmed
  const onAddressChange = async (address: Address) => {
    onUpdateBilling({
      ...billing,
      address: {
        ...billing?.address,
        ...address,
      },
    });
  };

  const onSameChange = (): void => {
    onUpdateBilling({
      ...billing,
      address_same_as_shipping: !billing?.address_same_as_shipping,
    });
  };

  //
  const isSame = billing?.address_same_as_shipping;

  return (
    <form>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <h2>Billing Information</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md checkbox-group">
            <input
              type="checkbox"
              name="taxExempt"
              disabled={disabled}
              onChange={onSameChange}
              checked={isSame}
            />
            <label onClick={onSameChange}>
              Billing Address Same As Shipping
            </label>
          </div>
        </div>

        <div className={`fade ${isSame ? 'hide' : 'show'}`}>
          {isSame ? null : (
            <>
              <div className="row">
                <div className="col-md">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      disabled={disabled}
                      placeholder="Name On Credit Card"
                      autoComplete="billing name"
                      value={billing?.address?.name || ''}
                      onChange={onFieldChange}
                      className={`form-control ${
                        billing?.address?.errors?.includes('name')
                          ? ' input-error'
                          : ''
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="form-group">
                    <EditableAddressBlock
                      disabled={disabled}
                      error={billing?.address?.errors?.includes('line1')}
                      onChange={onAddressChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div className="col-md">
            <div className="stripe-cardelement">
              <CardElement
                options={{
                  disabled,
                  style: {
                    base: {
                      fontSize: '14px',
                      fontSmoothing: 'antialiased',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
