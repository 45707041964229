import { FormattedDate } from './formatting';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useCreateKeyMutation, useDeleteKeyMutation } from '../query/mutations';
import { useKeysQuery } from '../query/queries';
import { FaKey, FaTrashAlt, FaPlus } from 'react-icons/fa';

import 'bootstrap/js/dist/dropdown';

const ROLE_OPTIONS = {
  read: { key: 'read', text: 'Read-Only', value: 'read' },
  write: { key: 'write', text: 'Read/Write', value: 'write' },
  admin: { key: 'admin', text: 'Admin', value: 'admin' },
};

const KeyHelper = ({ apiKey }: { apiKey: string }) => {
  const [isCopied, setCopied] = useState(false);

  return (
    <>
      <span
        style={{
          fontFamily: 'monospace',
          padding: '6px',
          marginRight: '5px',
          border: '1px dashed',
          backgroundColor: 'lightgreen',
        }}
      >
        {apiKey}
      </span>
      <CopyToClipboard text={apiKey} onCopy={() => setCopied(true)}>
        <button className="btn btn-primary btn-sm">
          {isCopied ? 'Copied!' : 'Click To Copy'}
        </button>
      </CopyToClipboard>
    </>
  );
};

export default ({ userId }) => {
  if (!userId) {
    return null;
  }
  const keysQuery = useKeysQuery(userId);
  const keys = keysQuery.data;

  const createKeyMutation = useCreateKeyMutation(userId);
  const deleteKeyMutation = useDeleteKeyMutation(userId);
  const [role, setRole] = useState('admin');
  const [newKey, setNewKey] = useState<Components.Schemas.ApiKey | undefined>();

  return (
    <div>
      {keysQuery.isLoading ? (
        <div data-iframe-height={true} className="align-center loader">
          <div className="spinner-border" role="status" />
          <h3>Loading API Keys</h3>
        </div>
      ) : (
        <table className="table">
          <tbody>
            {keys && (
              <>
                {keys.items?.map((key) => (
                  <tr key={key.id}>
                    <td>
                      <FaKey />
                      &nbsp;
                      {key.id === newKey?.id ? (
                        <KeyHelper apiKey={newKey?.key!} />
                      ) : (
                        <span
                          style={{
                            fontFamily: 'monospace',
                          }}
                        >
                          {key.key}
                        </span>
                      )}
                    </td>
                    <td>
                      <div>
                        <b>Role:</b> {ROLE_OPTIONS[key.role].text}
                      </div>
                      <div>
                        <b>Expires:</b>{' '}
                        {key.dateExpiration ? (
                          <FormattedDate date={key.dateExpiration} />
                        ) : (
                          'Never'
                        )}
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary align-center"
                        disabled={deleteKeyMutation.isLoading}
                        onClick={() => {
                          deleteKeyMutation.mutate({ keyId: key.id });
                        }}
                      >
                        {deleteKeyMutation.isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <FaTrashAlt />
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            )}

            <tr>
              <td colSpan={3}>
                <div className="btn-group">
                  <button
                    className="btn btn-primary"
                    disabled={createKeyMutation.isLoading}
                    onClick={() => {
                      createKeyMutation.mutate(
                        { role },
                        {
                          onSuccess: (createdKey) => setNewKey(createdKey),
                        },
                      );
                    }}
                  >
                    {createKeyMutation.isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <FaPlus />
                    )}
                    &nbsp;Create a new API key
                  </button>

                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Role: {ROLE_OPTIONS[role].text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {Object.values(ROLE_OPTIONS).map((blob) => (
                        <a
                          style={{ cursor: 'pointer' }}
                          key={`role-select-${blob.key}`}
                          className="dropdown-item"
                          onClick={(_) => setRole(blob.key)}
                        >
                          {blob.text}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
