import React from 'react';
import { Order } from '@kerfed/types';

import SafePrice from '../../SafePrice';

interface FooterPriceProps {
  order?: Order;
}

const FooterPrice = ({ order }: FooterPriceProps) => {
  return (
    <tbody className="partlist-total">
      <tr>
        <th colSpan={3}>
          <h2>Total Price</h2>
        </th>
      </tr>
      <tr>
        <th colSpan={2}>
          <h5>Fabrication Subtotal</h5>
        </th>
        <th>
          <h5>
            <SafePrice
              price={order?.total?.lines}
              message="One or more parts are missing required options."
            />
          </h5>
        </th>
      </tr>
      <tr>
        <th colSpan={2}>
          <h5>Discount</h5>
        </th>
        <th>
          <h5 style={{ color: 'green' }}>
            <SafePrice
              price={order?.total?.discount}
              message="Error applying discount code."
            />
          </h5>
        </th>
      </tr>
      <tr>
        <th colSpan={2}>
          <h2>Total</h2>
        </th>
        <th>
          <h2>
            <SafePrice
              price={order?.total?.total}
              message="One or more parts are missing required options."
            />
          </h2>
        </th>
      </tr>
    </tbody>
  );
};

export default FooterPrice;
