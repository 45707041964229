import { useState } from 'react';
import { signInAnonymously, onAuthStateChanged, User } from 'firebase/auth';

import { auth } from 'common/src/api/firebase';

export const useFirebaseAuth = () => {
  const [user, setUser] = useState<User>();

  onAuthStateChanged(auth, (tmpUser) => {
    if (tmpUser) {
      setUser(tmpUser);
    } else {
      signInAnonymously(auth).then((cred) => {
        setUser(cred.user);
      });
    }
  });

  return user;
};
