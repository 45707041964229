import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// import { getAnalytics } from 'firebase/analytics';

import config_public from '../../../config/src/config-public.json';

// Initialize Firebase
export const app = initializeApp(config_public.firebase);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
//export const analytics = getAnalytics(app);
// setPersistence(auth, browserSessionPersistence);

//const provider = new GoogleAuthProvider();
//export const signIn = () => signInWithRedirect(auth, provider);
