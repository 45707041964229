import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { quoteCreate } from '../api';
import FileUploader from './FileUploader';

type Props = {
  shopId: string;
  sparse?: boolean;
};

export default ({ shopId, sparse }: Props) => {
  // is the quote currently being uploaded
  const [creating, setCreating] = useState(false);
  // use to forward user to new page
  const navigate = useNavigate();

  const onUploads = async (uploadIds) => {
    if (uploadIds) {
      setCreating(true);
      try {
        // create the quote using the upload ID
        const quote = await quoteCreate(shopId, uploadIds);
        // forward the user to the new quote page
        navigate(`/quotes/${quote.order_id}`);
      } catch (err) {
        console.error({ err });
        setCreating(false);
      }
    }
  };

  return creating ? (
    <div data-iframe-height="true" className="loader">
      <div className="spinner-border " role="status" />
      <h3 className="p-3">Creating Quote</h3>
    </div>
  ) : (
    <div className="dropzone">
      <FileUploader onUploads={onUploads} sparse={sparse} />
    </div>
  );
};
