import React from 'react';

export default (props?: { code?: number }) => {
  const code = props?.code;

  return (
    <div className="container-md p-3 bg-white align-center">
      <div className="alert alert-secondary" role="alert">
        <img className="m-5 w-25" src="/static/images/frown.svg" />
        {code === 401 ? (
          <>
            <h1 className="alert-heading">401: Not Authorized</h1>
            <p>Did you use a signed link?</p>
          </>
        ) : (
          <>
            <h1 className="alert-heading">404: Not Found</h1>
            <p>Page doesn't exist.</p>
          </>
        )}
      </div>
      <div className="segmentRectangle" />
    </div>
  );
};
