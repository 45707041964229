import React from 'react';
import { PartConfiguration } from '@kerfed/client';
import { Shop, Order } from '@kerfed/types';

import EditablePartList from './EditablePartList';
import FooterPrice from './FooterPrice';
import CircuralLoading from '../CircuralLoading';

interface EditablePartListProps {
  order?: Order;
  shop?: Shop;
  onChangeMethod: (partId: string, newSelectedMethodId: string) => void;
  onChangePartOrderData: (
    lineId: string,
    partId: string,
    newConfig: PartConfiguration,
  ) => void;
}

const EditablePart = ({
  order,
  shop,
  onChangeMethod,
  onChangePartOrderData,
}: EditablePartListProps) => {
  const handleChangeOrderData = (
    partId: string,
    newConfig: PartConfiguration,
  ) => {
    if (!order?.lines?.length) return;

    const line = order.lines.find((line) => line?.config?.part_id === partId);
    if (line?.line_id) onChangePartOrderData(line.line_id, partId, newConfig);
  };

  if (!order?.order_id) return null;

  if (!order?.parts?.length) {
    return (
      <div>
        <CircuralLoading label="Loading Parts" />
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-md">
          <div className="table-responsive bordered mb-3">
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th>Part</th>
                  <th className="price-column">Unit Price</th>
                  <th className="price-column">Total Price</th>
                </tr>
              </thead>

              <EditablePartList
                order={order}
                shop={shop}
                onChangePartOrderData={handleChangeOrderData}
                onChangeMethod={onChangeMethod}
              />

              {!!order?.parts?.length && <FooterPrice order={order} />}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditablePart;
