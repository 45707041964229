import { useEffect, useRef, useState } from 'react';
import {
  convertTimestampToDate,
  getNewExponentialBackoffTime,
} from '@kerfed/common/utils';
import { PartConfiguration } from '@kerfed/client';
import { Shop, Order, TFabricationMethod } from '@kerfed/types';

import {
  useAPIGetOrder,
  useAPIOrderLineEdit,
  useAPIOrderFileAdd,
} from 'app/src/api/order';
import { useAPIGetShop } from 'app/src/api/shop';

import { INITIAL_BACKOFF_TIME_MS, MAP_METHOD } from 'app/src/constants';

type TUseQuoteEditControllerParams = {
  quoteId?: string;
};

export const useQuoteEditController = ({
  quoteId = '',
}: TUseQuoteEditControllerParams) => {
  const backoffTime = useRef(INITIAL_BACKOFF_TIME_MS);
  const dateModified = useRef<Date>();
  const backoffTimeModel = useRef(INITIAL_BACKOFF_TIME_MS);
  const retryFetchOrderCount = useRef(0);
  const initialModelCount = useRef(0);
  const [order, setOrder] = useState<Order>();
  const [shop, setShop] = useState<Shop>();
  const [inProgressFileCount, setInProgressFileCount] = useState(0);

  const { onGetShop } = useAPIGetShop({
    onCompleted: (res) => {
      setShop(res);
    },
  });

  const { onOrderLineEdit } = useAPIOrderLineEdit({
    onCompleted: () => {
      if (quoteId) {
        /**
         * Refresh the order data after successfully editing line
         * But wait a bit before making the request
         */
        setTimeout(() => {
          onGetOrder({
            variables: {
              options: {
                pathParams: { orderId: quoteId },
                dateSince: dateModified.current,
              },
            },
          });
        }, 1500);
      }
    },
    onError: () => {
      /**
       * Stop all loading price when encounter an error
       */
      handleStopAllLoadingPrice();
    },
  });

  const { loading, error, onGetOrder } = useAPIGetOrder({
    onCompleted: (res) => {
      dateModified.current = convertTimestampToDate(
        res?.date_modified?.seconds,
      );
      setOrder((prev) => ({
        ...res,
        parts: res?.parts?.map((part) => {
          const line = res?.lines?.find(
            (line) => line?.config?.part_id === part?.part_id,
          );
          const prevPart = prev?.parts?.find(
            (prevPart) => prevPart?.part_id === part.part_id,
          );

          return {
            ...part,
            /**
             * Persist the previous updated data if exists
             */
            ...prevPart,

            quantity: line?.config.quantity,
            selected_method:
              MAP_METHOD?.[line?.config?.method_id as TFabricationMethod],
            loadingPrice: false,
          };
        }),
        lines: res?.lines?.map((line) => {
          const prevLine = prev?.lines?.find(
            (pLine) => pLine.line_id === line.line_id,
          );
          return {
            ...line,
            config: {
              ...line?.config,
              notes: prevLine?.config?.notes,
            },
          };
        }),
      }));

      /**
       * If request still not contains parts
       * Re-pool the data by using backoff time
       * Otherwise set the backoff time to the initial time
       */
      if (!res?.parts?.length) handlePoolInitialOrderData();
      else backoffTime.current = INITIAL_BACKOFF_TIME_MS;

      /**
       * Restert the retry count to 0 if successfully fetch the data
       */
      retryFetchOrderCount.current = 0;
    },
    onError: () => {
      /**
       * Retry the request if getting error
       * and not reach maximum retry count
       *
       * Otherwise stop all loading price if exists
       */
      if (retryFetchOrderCount.current <= 3) {
        handlePoolInitialOrderData();

        /**
         * Increase the retry count after making request
         */
        retryFetchOrderCount.current = retryFetchOrderCount.current + 1;
      } else {
        handleStopAllLoadingPrice();
      }
    },
  });

  const { onOrderFileAdd } = useAPIOrderFileAdd({});

  const handleStopAllLoadingPrice = () => {
    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        parts: prev?.parts?.map((part) => ({
          ...part,
          loadingPrice: false,
        })),
      };
    });
  };

  const handleOrderLineEdit = (
    lineId: string,
    newConfig: PartConfiguration,
  ) => {
    if (!quoteId) return;

    onOrderLineEdit({
      variables: {
        options: {
          pathParams: {
            lineId,
            orderId: quoteId,
          },
        },
        params: {
          ...newConfig,
          expedite: newConfig.expedite || 'standard',
        },
      },
    });
  };

  /**
   * Initially the API will not return with the parts data
   * we should re-pull the data until we get the parts data.
   *
   * Re-pulling will be done by using the exponential backoff
   *
   */
  const handlePoolInitialOrderData = () => {
    const newBackoffTime =
      backoffTime.current + Math.round(backoffTime.current / 2);
    backoffTime.current = newBackoffTime;
    setTimeout(() => {
      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
      });
    }, newBackoffTime);
  };

  const handleChangeMethod = (partId: string, newSelectedMethodId: string) => {
    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        parts: prev?.parts?.map((part) => {
          if (part.part_id === partId) {
            return {
              ...part,
              /**
               * Since we are going to hit the pricing API
               * we need show the loading indicator by set
               * loadingPrice as true
               */
              loadingPrice: true,
              selected_method: newSelectedMethodId,
            };
          }

          return part;
        }),
      };
    });

    const part = order?.parts?.find((part) => part?.part_id === partId);
    const line = order?.lines?.find((line) => line?.config?.part_id === partId);

    /**
     * Request to API line edit everytime
     * the user change the method
     */
    if (part?.[newSelectedMethodId]?.defaults && line?.line_id) {
      handleOrderLineEdit(line.line_id, {
        ...part[newSelectedMethodId].defaults,
        quantity: part.quantity,
      });
    }
  };

  const handleChangePartOrderData = (
    lineId: string,
    partId: string,
    newConfig: PartConfiguration,
  ) => {
    setOrder((prev) => {
      if (!prev) return;

      return {
        ...prev,
        parts: prev?.parts?.map((part) => {
          if (part?.part_id === partId) {
            const methodId = MAP_METHOD?.[newConfig.method_id || ''];
            if (!methodId || !part?.[methodId]) return part;

            return {
              ...part,
              notes: newConfig.notes,
              quantity: newConfig.quantity,
              loadingPrice: true,
              [methodId]: {
                ...part[methodId],
                defaults: newConfig,
              },
            };
          }

          return part;
        }),
        lines: prev?.lines?.map((line) => {
          if (line.line_id === lineId) {
            return {
              ...line,
              config: newConfig,
            };
          }
          return line;
        }),
      };
    });

    /**
     * Request to API line edit everytime
     * the user made a change on the part configuration
     * expect change on the notes
     */
    handleOrderLineEdit(lineId, newConfig);
  };

  /**
   * Handle pooling order data after adding a new file to the
   * existing order by checking the count ot models on the API request already
   * match with the expected count or no
   *
   * @param expectedModelsCount the new expected count of the models
   */
  const handleRefetchOrderDataAfterAddFile = (expectedModelsCount: number) => {
    if (expectedModelsCount > 0 && quoteId) {
      const newBackoffTime = getNewExponentialBackoffTime(
        backoffTimeModel.current,
      );
      backoffTimeModel.current = newBackoffTime;

      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
        onCompleted: (res) => {
          const newModelsCount = res?.models?.length || 0;

          /**
           * If expected count smaller or equal new model count
           * make the inprogress count as empty and reset the initial backoff time
           *
           * Otherwise re-pull the order data by using backoff time
           * and recalculate how many files that are currently in progress
           * by the expected count with new modal count from the API response
           *
           */
          if (expectedModelsCount <= newModelsCount) {
            setInProgressFileCount(0);
            initialModelCount.current = 0;
            backoffTimeModel.current = INITIAL_BACKOFF_TIME_MS;
          } else {
            const tmpInProgressFileCount = expectedModelsCount - newModelsCount;
            setInProgressFileCount(tmpInProgressFileCount);
            setTimeout(() => {
              handleRefetchOrderDataAfterAddFile(expectedModelsCount);
            }, newBackoffTime);
          }
        },
      });
    }
  };

  const handleAddOrderFile = async (uploadIds: string[]) => {
    if (quoteId && uploadIds.length) {
      initialModelCount.current = order?.models?.length || 0;
      let tmpProgressFileCount = 0;
      for (const fileId of uploadIds) {
        await onOrderFileAdd({
          variables: {
            params: {
              source: {
                file_id: fileId,
              },
            },
            options: {
              pathParams: { orderId: quoteId },
            },
          },
          onCompleted: () => {
            tmpProgressFileCount += 1;
          },
        });
      }

      setInProgressFileCount(tmpProgressFileCount);
      if (tmpProgressFileCount > 0) {
        handleRefetchOrderDataAfterAddFile(
          initialModelCount.current + tmpProgressFileCount,
        );
      }
    }
  };

  useEffect(() => {
    if (quoteId && !order) {
      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
      });
    }
  }, [quoteId, order]);

  useEffect(() => {
    if (order?.shop_id && !shop) {
      onGetShop({
        variables: {
          options: { pathParams: { shopId: order.shop_id } },
        },
      });
    }
  }, [order?.shop_id, shop]);

  return {
    loading,
    order,
    shop,
    inProgressFileCount,
    errorGetOrder: error,
    onChangeMethod: handleChangeMethod,
    onChangePartOrderData: handleChangePartOrderData,
    onAddOrderFile: handleAddOrderFile,
  };
};
