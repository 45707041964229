import {
  Order,
  TOrderFileAddReq,
  TOrderLineEditReq,
  TOrderLineEditRes,
} from '@kerfed/types';

import { fetcher } from '../utils/fetcher';

import { TDefaultApiParams } from '../types';

export const useAPIGetOrder = ({
  onCompleted,
  onError,
}: TDefaultApiParams<Order>) => {
  const [onGetOrder, { loading, error }] = fetcher<Order, any>({
    uri: `orders/orderId`,
    variables: {
      options: { method: 'GET' },
    },
    onCompleted,
    onError,
  });

  return { onGetOrder, loading, error };
};

export const useAPIOrderLineEdit = ({
  onCompleted,
  onError,
}: TDefaultApiParams<TOrderLineEditRes>) => {
  const [onOrderLineEdit, { loading }] = fetcher<
    TOrderLineEditRes,
    TOrderLineEditReq
  >({
    uri: `orders/orderId/price/lineId`,
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onOrderLineEdit, loading };
};

export const useAPIOrderFileAdd = ({
  onCompleted,
  onError,
}: TDefaultApiParams<any>) => {
  const [onOrderFileAdd, { loading }] = fetcher<any, TOrderFileAddReq>({
    uri: `orders/orderId/file`,
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onOrderFileAdd, loading };
};
