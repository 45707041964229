import React from 'react';
import { LinePrice } from '@kerfed/types';

import CircuralLoading from '../../CircuralLoading';
import OptionalPrice from '../OptionalPrice';

interface ItemRowPriceProps {
  loading?: boolean;
  enabled?: boolean;
  linePrice?: LinePrice;
}

const ItemRowPrice = ({
  loading = false,
  enabled = false,
  linePrice,
}: ItemRowPriceProps) => {
  return (
    <>
      <td>
        {!!loading ? (
          <CircuralLoading />
        ) : (
          <h2>
            <OptionalPrice enabled={enabled} price={linePrice?.unit} />
          </h2>
        )}
      </td>
      <td>
        {!!loading ? (
          <CircuralLoading />
        ) : (
          <h2>
            <OptionalPrice enabled={enabled} price={linePrice?.total} />
          </h2>
        )}
      </td>
    </>
  );
};

export default ItemRowPrice;
