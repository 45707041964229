import React, { useMemo } from 'react';
import { PartConfiguration, FlatFabrication } from '@kerfed/client';

import FileImage from '../../FileImage';
import EditablePartConfiguration from '../EditablePartConfiguration';

import { EditablePartProps } from './types';

interface EditableFlatPartProps extends EditablePartProps {
  data: FlatFabrication;
}

const EditableFlatPart = ({
  partId,
  data,
  shop,
  config,
  onChangePartOrderData,
}: EditableFlatPartProps) => {
  const strExtents = useMemo(() => {
    if (!data?.extents || data?.extents?.length < 2) return '';

    let tmpStrExtents = `${data.extents[0].toFixed(2)} x ${data.extents[1].toFixed(2)}`;
    if (data?.thickness) {
      tmpStrExtents += ` x ${data?.thickness.toFixed(2)}`;
    }

    return tmpStrExtents;
  }, [data?.extents, data?.thickness]);

  const handleChangePartOrderData = (newConfig: PartConfiguration) => {
    onChangePartOrderData(partId, newConfig);
  };

  return (
    <div>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            {!!strExtents && <span className="fw-bold">{strExtents}</span>}

            <EditablePartConfiguration
              posts={data.posts}
              shop={shop}
              config={config || data.defaults}
              onChangePartOrderData={handleChangePartOrderData}
            />
          </div>
          <div className="col-md">
            {data?.drawing?.url && <FileImage url={data.drawing.url} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableFlatPart;
