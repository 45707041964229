import React from 'react';
import {
  PartConfiguration,
  FlatFabrication,
  MillFabrication,
  RollFabrication,
  AddFabrication,
  TurnFabrication,
  BentFabrication,
  CotsFabrication,
} from '@kerfed/client';
import { Shop, TMethod, TPartFabrication } from '@kerfed/types';

import EditableFlatPart from './EditableFlatPart';
import EditableMillPart from './EditableMillPart';
import EditableRollPart from './EditableRollPart';
import EditableAddPart from './EditableAddPart';
import EditableTurnPart from './EditableTurnPart';
import EditableBentPart from './EditableBentPart';
import EditableCotsPart from './EditableCotsPart';

interface EditableMethodPartProps {
  methodId: TMethod;
  data: TPartFabrication;
  shop?: Shop;
  notes?: string;
  quantity?: number;
  config?: PartConfiguration;
  onChangePartOrderData: (partId: string, newConfig: PartConfiguration) => void;
}

const EditableMethodPart = (props: EditableMethodPartProps) => {
  const { methodId, data, ...rest } = props;

  const constructedData: TPartFabrication[typeof methodId] = {
    ...data?.[methodId],
    defaults: {
      ...data?.[methodId]?.defaults,
      notes: data.notes,
      quantity: data.quantity,
    },
  };

  if (!data?.part_id) return null;

  switch (methodId) {
    case 'flat':
      return (
        <EditableFlatPart
          {...rest}
          partId={data?.part_id || ''}
          data={constructedData as FlatFabrication}
        />
      );
    case 'mill':
      return (
        <EditableMillPart
          {...rest}
          partId={data.part_id}
          data={constructedData as MillFabrication}
        />
      );
    case 'roll':
      return (
        <EditableRollPart
          {...rest}
          partId={data.part_id}
          data={constructedData as RollFabrication}
        />
      );
    case 'add':
      return (
        <EditableAddPart
          {...rest}
          partId={data.part_id}
          data={constructedData as AddFabrication}
        />
      );
    case 'turn':
      return (
        <EditableTurnPart
          {...rest}
          partId={data.part_id}
          data={constructedData as TurnFabrication}
        />
      );
    case 'bent':
      return (
        <EditableBentPart
          {...rest}
          partId={data.part_id}
          data={constructedData as BentFabrication}
        />
      );
    case 'cots':
      return (
        <EditableCotsPart
          {...rest}
          partId={data.part_id}
          data={constructedData as CotsFabrication}
        />
      );
    default:
      return <div>Unable to automatically quote part.</div>;
  }
};

export default EditableMethodPart;
