import { useMutation, useQueryClient } from 'react-query';
import * as api from '../api';

import {
  KEYS_QUERY,
  SHOP_QUERY,
  SHOP_EDIT_QUERY,
  USER_CURRENT_QUERY,
  USER_QUERY,
} from './keys';

export const useCreateKeyMutation = (userId: string) => {
  const cache = useQueryClient();
  return useMutation<
    Paths.UserKeyCreate.Responses.$201,
    any,
    Paths.UserKeyCreate.RequestBody
  >((args) => api.userKeyCreate(userId, args), {
    onSuccess: () => {
      cache.invalidateQueries([KEYS_QUERY, userId]);
    },
  });
};

export const useDeleteKeyMutation = (userId: string) => {
  const cache = useQueryClient();
  return useMutation<
    Paths.UserKeyDelete.Responses.$200,
    any,
    {
      keyId: string;
    }
  >((args) => api.userKeyDelete(userId, args.keyId), {
    onSuccess: () => {
      cache.invalidateQueries([KEYS_QUERY, userId]);
    },
  });
};

export const useShopEditMutation = (shopId: string) => {
  const cache = useQueryClient();
  return useMutation<
    Paths.ShopEditPut.Responses.$200,
    any,
    { shopContent: any }
  >((args) => api.shopEditPut(shopId, args.shopContent), {
    onSuccess: () => {
      cache.invalidateQueries([SHOP_QUERY, shopId]);
      cache.invalidateQueries([SHOP_EDIT_QUERY, shopId]);
    },
  });
};

export const useUserCurrentMutation = () => {
  const client = useQueryClient();
  client.invalidateQueries([USER_CURRENT_QUERY]);
};

export const useUserMutation = (userId: string) => {
  const cache = useQueryClient();
  return useMutation<Paths.UserUpdate.Responses.$200, any>(
    (args) => api.userUpdate(userId, args),
    {
      onSuccess: () => {
        cache.invalidateQueries([USER_QUERY, userId]);
      },
    },
  );
};
