import { EmbedContext } from '../contexts';
import config_public from '../../../config/src/config-public.json';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import { useShopQuery } from '../query/queries';

// this API query should be URL limited to our domains
const API_KEY = config_public?.google?.maps_api;

/**
 * Return an Embed containing a Google Maps viewer.
 *
 * @param name display name
 * @param address address object
 */
const MapEmbed = ({ name, address }: { name?: string; address?: any }) => {
  if (API_KEY && address && name) {
    // encode the address into a URL- safe string
    const query = encodeURI(
      `${name},${address.street1 || ''} ${address.city} ${address.state} ${
        address.zip
      }`,
    );
    // construct URL for places API query
    const url = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${query}`;

    return <iframe className="maps-embed" loading="lazy" src={url} />;
  }
  return null;
};

/**
 * Render information about a shop.
 */
export default ({ shopId }: { shopId: string }) => {
  const { isEmbedded } = useContext(EmbedContext);
  // never display the shop info blurb on embeds
  if (!shopId || isEmbedded || shopId === 'kerfed') {
    return null;
  }

  // query the shop doc
  const shopQuery = useShopQuery(shopId);
  const shop = shopQuery.data;
  const address = shop?.address;

  return shopQuery.isLoading || !API_KEY ? (
    <div data-iframe-height={true} className="align-center loader">
      <div className="spinner-border" role="status" />
      <h3 className="p-3">Loading Shop Details</h3>
    </div>
  ) : (
    <div>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <h1>{shop?.name || ''}</h1>
            {address?.email && (
              <a href={`mailto:${address?.email}`}>{address?.email}</a>
            )}
            {address?.phone && <p>{address?.phone}</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <ReactMarkdown source={shop?.description} />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <MapEmbed name={shop?.name} address={address} />
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};
