import React, { useMemo } from 'react';
import { formatUuid, getUUIDFromFileId } from '@kerfed/common/utils';
import { TScene } from '@kerfed/types';

import Explainer from 'app/src/components/Explainer';

interface DetectionProps {
  geomId?: string | null;
  models?: TScene[];
}

const Detection = ({ geomId, models = [] }: DetectionProps) => {
  const detection = useMemo(() => {
    if (models.length && geomId) {
      const detectionFileIds: string[] = [];

      /**
       * Get fileId by using geomId by checking
       * whether the given geomId from specific part are
       * listed in the models or not.
       */
      for (const model of models) {
        const foundInModel = model?.geometries?.some(
          (geom) => geom.geom_id === geomId,
        );
        if (foundInModel && model?.original?.file_id) {
          detectionFileIds.push(model?.original?.file_id);
        }
      }

      /**
       * Construct data to display on the UI
       * if fileId founded on the models
       */
      if (detectionFileIds.length) {
        const detectionMap = detectionFileIds.reduce(
          (mapDetection, fileId) => {
            const uuidFileId = getUUIDFromFileId(fileId);
            return {
              ...mapDetection,
              [uuidFileId]: (mapDetection?.[uuidFileId] || 0) + 1,
            };
          },
          {} as { [fileId: string]: number },
        );

        return {
          count: detectionFileIds.length,
          description: Object.entries(detectionMap)
            .sort()
            .map(
              ([fileId, quantity]) =>
                `${quantity} found in FID ${formatUuid(fileId)}`,
            )
            .join('\n'),
        };
      }
    }
    return { count: 0 };
  }, [geomId, models]);

  return (
    <Explainer explanation={detection?.description}>
      <b>{detection.count}</b> detected in assembly
    </Explainer>
  );
};

export default Detection;
