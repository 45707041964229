import { auth } from '../../../common/src/api/firebase';
import { validateEmail } from '../../../common/src/utils';
import React, { ChangeEvent, Component } from 'react';

type Status = 'closed' | 'filling' | 'sending' | 'done' | 'error';

type Props = {
  isEmphasized: boolean;
  quoteId: string;
  disabled: boolean;
  // TODO: is there a terser way to represent this mutation?
  onOrderCreate: ActionType<typeof actions.orderCreate>;
};

type State = {
  emails: string[];
  orderId?: string;
  status: Status;
  message: string;
  notes: string;
};

class OrderEmailButton extends Component<Props, State> {
  state = {
    emails: [''],
    message: '',
    orderId: undefined,
    status: 'closed' as Status,
    notes: '',
  };

  onSubmit = async (event: any) => {
    const { quoteId, onOrderCreate } = this.props;
    const { emails, notes } = this.state;

    // remove invalid emails
    const validEmails = emails.filter(
      (email) => !!email && validateEmail(email),
    );
    this.setState({ status: 'sending' });

    try {
      if (!validEmails.length) {
        throw new Error('At least one valid email must be provided!');
      }
      const orderId = await onOrderCreate({
        quoteId,
        emails: validEmails,
        message: notes,
      });
      if (orderId) {
        this.setState({ orderId, status: 'done' });
      } else {
        this.setState({ status: 'error' });
      }
    } catch (err) {
      this.setState({ status: 'error' });
    }
  };

  onOpen = () => {
    const { status } = this.state;

    if (status === 'closed') {
      this.setState({ status: 'filling' });
      // if the current user has an email defined fill that in as the default
      if (auth?.currentUser?.email) {
        this.setState({ emails: [auth.currentUser.email, ''] });
      }
    } else {
      this.setState({ status: 'closed' });
    }
  };

  onClose = () => {
    this.setState({ status: 'closed' });
  };

  onEmailChange = (idx: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event && event.target && event.target.value;

    this.setState((state) => {
      const emails = [...this.state.emails];
      if (value) {
        emails[idx] = value;
        if (idx === emails.length - 1) {
          emails.push('');
        }
      } else if (idx !== emails.length - 1) {
        emails.splice(idx, 1);
      }
      return {
        ...state,
        emails,
      };
    });
  };

  onTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ notes: event?.target?.value as string });
  };

  PresendDescription = ({ status }) => {
    const { notes, emails, message } = this.state;

    return (
      <>
        <div data-iframe-height="true">
          <p>Enter each recipient's email address:</p>
          {emails.map((email, idx) => (
            <div key={idx}>
              <div className="input-group mb-1">
                <input
                  type="text"
                  className="form-control rounded-0"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  disabled={status === 'sending'}
                  value={email}
                  placeholder="user@example.com"
                  autoComplete="email"
                  style={{
                    backgroundColor:
                      !!email && !validateEmail(email)
                        ? 'rgba(255, 0, 0, 0.1)'
                        : 'unset',
                  }}
                  onChange={this.onEmailChange.bind(this, idx)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="my-2">
          <p>Enter any additional notes or information here:</p>
          <textarea
            className="form-control rounded-0"
            value={notes}
            onChange={this.onTextChange}
            placeholder="These are the parts for project X, please bill them to account Y."
            rows={3}
          />
        </div>

        {status === 'error' ? (
          <div className="alert alert-danger rounded-0" role="alert">
            <h2 className="alert-heading">
              There was an error when creating your RFQ
            </h2>
            <p>
              Check that the email addresses are typed correctly and verify that
              the configured quote has a valid price.
            </p>
            {message ? <p>{message}</p> : null}
          </div>
        ) : null}
      </>
    );
  };

  CompletedDescription = () => {
    const { notes, emails } = this.state;
    return (
      <div className="alert alert-success rounded-0" role="alert">
        <h2 className="alert-heading">Request Recieved!</h2>
        <p>Your quote will be sent to the following email addresses:</p>
        <ul>
          {emails
            .filter((email) => !!email) // Ignore empty email fields.
            .map((email, idx) => (
              <li key={idx}>{email}</li>
            ))}
        </ul>
        {notes ? (
          <p>
            <i>Notes: </i>
            {notes}
          </p>
        ) : null}
      </div>
    );
  };

  render() {
    const { status } = this.state;
    const { isEmphasized, disabled } = this.props;
    // show the widget for any non-closed status
    const expanded = status !== 'closed';
    // so we can put loaders up and disable buttons for multiple clicks
    const isSending = status === 'sending';

    return (
      <>
        <button
          className={`btn btn-lg  ${
            isEmphasized || expanded ? 'btn-primary' : 'btn-outline-primary'
          }`}
          disabled={disabled}
          onClick={this.onOpen}
        >
          Email This Quote
        </button>

        {expanded ? (
          <div className="order-email-expanded">
            <p>
              Email a copy of this quote to yourself or others in your
              organization. A PDF of this quote will be sent, as well as a
              signed link that can be paid with a credit card to place the
              order. Note that the quantities, finishes, materials, etc, in the
              mailed quoted will be the{' '}
              <strong>same as configured on this page</strong>. If you haven't
              configured the parts in this quote, we recommend that you
              configure parts before emailing the quote.
            </p>
            {['filling', 'sending', 'error'].includes(status) ? (
              <this.PresendDescription status={status} />
            ) : null}
            {status === 'done' ? (
              <this.CompletedDescription />
            ) : (
              <button
                type="button"
                disabled={isSending}
                className="btn btn-lg btn-outline-primary"
                onClick={this.onSubmit}
              >
                Submit Email Quote{' '}
                {isSending && (
                  <span
                    className="spinner-border spinner-button"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}

            <button
              type="button"
              disabled={isSending}
              className="btn btn-lg btn-outline-secondary"
              onClick={this.onClose}
            >
              Close
            </button>
          </div>
        ) : null}
      </>
    );
  }
}

export default OrderEmailButton;
