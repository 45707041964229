import React, { useEffect, useState } from 'react';
import { htmlToUnicode } from '@kerfed/common/utils';
import { TPartFabrication } from '@kerfed/types';

import { TMethods } from '../types';
import { PROCESS_OPTIONS } from 'app/src/constants';

interface MethodSelectorProps {
  part: TPartFabrication;
  selectedMethod: string;
  onChange: (selectedMethod: string) => void;
}

const MethodSelector = ({
  part,
  selectedMethod,
  onChange,
}: MethodSelectorProps) => {
  const [methods, setMethods] = useState<TMethods[]>([]);

  useEffect(() => {
    const tmpMethods: TMethods[] = PROCESS_OPTIONS.map((opt) => {
      return {
        key: opt.key,
        label: opt.text,
        loaded: !!part?.[opt.key]?.outcome,
        success: !!part?.[opt.key]?.outcome?.is_success,
        errors: part?.[opt.key]?.outcome?.errors,
        error: part?.[opt.key]
          ? !!part?.[opt.key]?.outcome?.errors?.length
          : true,
      };
    });

    setMethods(tmpMethods);
  }, [part]);

  return (
    <div className="part-config-group pb-0">
      <div
        className="btn-group btn-method-group my-2"
        role="group"
        aria-label="Part manufacturing methods"
      >
        {methods.map((method) => {
          const disabled = !method.success || !!method.error || !method.loaded;
          return (
            <div className="col" key={`method-${method.key}`}>
              <button
                data-toggle="tooltip"
                data-placement="top"
                title={
                  method.errors?.[0]?.message ||
                  (method.success || method.error
                    ? ''
                    : 'Hang tight, still processing.')
                }
                className={`btn w-100 h-100 btn-method ${
                  method.key === selectedMethod
                    ? 'btn-primary'
                    : method.error
                      ? method.loaded
                        ? 'btn-outline-danger'
                        : 'btn-outline-secondary'
                      : 'btn-outline-primary'
                }`}
                style={{
                  pointerEvents: 'auto',
                  cursor: disabled ? 'default' : 'pointer',
                }}
                disabled={disabled}
                onClick={() => onChange(method.key)}
              >
                {!method.success && !method.error && (
                  <span
                    className="spinner-border spinner-button"
                    role="status"
                    aria-hidden="true"
                  />
                )}

                {htmlToUnicode(method.label)}
              </button>
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
};

export default MethodSelector;
