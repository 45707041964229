/**
 * FancyCard
 * -------------------
 *
 * Display an image-text block in alternating right and left.
 */
import { stringHash } from '../../../common/src/utils';
import React from 'react';

import '../styles/FancyCard.scss';

export type CardProps = {
  title: string;
  text: string;
  image: string;
  link?: string;
  divClass?: string;
};

// https://codepen.io/daiquiri/pen/GWpPKP
export const FancyCard = ({
  title,
  text,
  image,
  link,
  divClass,
}: CardProps) => {
  const hashed = stringHash(title);
  return (
    <div className={(divClass || 'col-lg-7 px-0') + ' fancy-card'}>
      <figure>
        <div
          className="media"
          style={{
            backgroundImage: 'url(' + image + ')',
          }}
        />
        <figcaption>
          <div className="body">
            <h2>{title.toLocaleUpperCase()}</h2>
            <p>{text}</p>
          </div>
        </figcaption>
        <a href={link || '#'} />
      </figure>
    </div>
  );
};
