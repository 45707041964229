import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { PartConfiguration, Postprocess } from '@kerfed/client';
import { Shop } from '@kerfed/client';

import EditablePostOption from '../EditablePostOption';
import EditablePartConfigs from '../EditablePartConfigs';
import NoteSection from 'app/src/components/EditablePart/NoteSection';

interface EditablePartConfigurationProps {
  posts?: Postprocess[] | null;
  shop?: Shop;
  config?: PartConfiguration | null;
  onChangePartOrderData: (newConfig: PartConfiguration) => void;
}

const EditablePartConfiguration = ({
  posts,
  shop,
  config,
  onChangePartOrderData,
}: EditablePartConfigurationProps) => {
  const [notes, setNotes] = useState('');

  const handleChangePostOption = (newSelectedPostId: string) => {
    const postProcess = config?.posts?.some(
      (post) => post === newSelectedPostId,
    )
      ? config?.posts.filter((post) => post !== newSelectedPostId)
      : config?.posts
        ? [...config.posts, newSelectedPostId]
        : [newSelectedPostId];

    onChangePartOrderData({
      ...config,
      posts: postProcess,
    });
  };

  const debounceChangeNotes = useCallback(
    debounce((newNotes: string) => {
      onChangePartOrderData({
        ...config,
        notes: newNotes,
      });
    }, 500),
    [onChangePartOrderData],
  );

  const handleChangeNotes = (newNotes?: string) => {
    if (!config) return;
    setNotes(newNotes || '');
    debounceChangeNotes(newNotes || '');
  };

  useEffect(() => {
    setNotes(config?.notes || '');
  }, [config?.notes]);

  return (
    <div className="pb-4">
      <div className="part-config-group">
        {shop?.shop_id && (
          <EditablePartConfigs
            shop={shop}
            config={config}
            onChangePartOrderData={onChangePartOrderData}
          />
        )}

        {!!posts?.length && (
          <>
            <p>Configure Postprocessing Options</p>
            <EditablePostOption
              posts={posts}
              config={config}
              onChange={handleChangePostOption}
            />
          </>
        )}
      </div>

      <NoteSection note={notes} onNoteChange={handleChangeNotes} />
    </div>
  );
};

export default EditablePartConfiguration;
