import { htmlToUnicode } from '../../../common/src/utils';
import React, { useMemo } from 'react';
import { OrderEmailButton } from '../components';
import { useShopQuery } from '../query/queries';
import EditableAddressBlock from './EditableAddressBlock';
import ErrorBoundary from './ErrorBoundary';

interface ExpediteDescription extends Components.Schemas.OptionDescription {
  cycleMax?: number;
}

type ExpediteSliderProps = {
  expediteId: string;
  expediteValues: ExpediteDescription[];
  onExpediteChange(expediteId?: string): any;
};

const ExpediteSlider = ({
  expediteId,
  expediteValues,
  onExpediteChange,
}: ExpediteSliderProps) => {
  return (
    <span>
      {Object.entries(expediteValues || {}).map(([k, v]) => (
        <button
          className={
            k === expediteId
              ? 'btn btn-expedite btn-primary'
              : 'btn btn-expedite btn-outline-secondary'
          }
          key={k}
          disabled={k === expediteId}
          onClick={() => onExpediteChange(k)}
        >
          {htmlToUnicode(v.text)}
        </button>
      ))}
    </span>
  );
};

type DiscountTextBoxProps = {
  discountId: string;
  isDiscounting: boolean;
  discount?: number;
  onDiscountChange(discountId?: string): any;
};

const DiscountTextBox = ({
  discountId,
  isDiscounting,
  onDiscountChange,
  discount,
}: DiscountTextBoxProps) => {
  // if the discount is null/undefined we don't
  // have any information; if it is nonzero it
  // is valid, otherwise it is invalid
  const dstate =
    discount === null || discount === undefined
      ? ''
      : discount > 0.0001
        ? 'is-valid'
        : 'is-invalid';
  return (
    <>
      <input
        className={`form-control discount-box ${dstate}`}
        placeholder="Discount Code"
        disabled={isDiscounting}
        onChange={(event) => onDiscountChange(event.target.value)}
        value={discountId}
      />
      {dstate === 'is-valid' && (
        <div className="valid-feedback">{`${(discount * 100).toFixed(
          1,
        )}% Off`}</div>
      )}
      {dstate === 'is-invalid' && (
        <div className="invalid-feedback">Invalid Code</div>
      )}
    </>
  );
};

// TODO: I think this was fixed in more recent versions of library:
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/17181#issuecomment-362392689
interface OrderButtonProps extends RouteComponentProps<{}> {
  quoteId: string;
  disabled: boolean;
}
const OrderCreateButton = ({ history, quoteId, disabled }) => {
  const dispatch = useDispatch();

  const disabledNote =
    'Not all parts have configured prices! ' +
    'Finish configuring them, or set their quantity to zero to check out.';

  return (
    <button
      className="btn btn-lg btn-primary"
      disabled={disabled}
      title={(disabled && disabledNote) || ''}
      onClick={async () => {
        const orderId = await dispatch(actions.orderCreate({ quoteId }));
        if (orderId) {
          history.push(`/orders/${orderId}`);
        }
      }}
    >
      Check Out Now &#8594;
    </button>
  );
};

// TODO: I think this was fixed in more recent versions of library:
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/17181#issuecomment-362392689
interface CheckoutProps extends RouteComponentProps<{}> {
  quoteId: string;
  quoteOptions: any;
  quoteComputed: any;
  shopId: string;
  shop?: any;
  expediteId?: string;
  onDiscountUpdate: any;
  onExpediteUpdate?: any;
  onAddressUpdate?: any;
}

export default ({
  quoteComputed,
  quoteId,
  shopId,
  expediteId,
  quoteOptions,
  onDiscountUpdate,
  onExpediteUpdate,
  onAddressUpdate,
}: CheckoutProps) => {
  // Are any of the parts currently still computing price.
  const isComputingPrice = useMemo(() => {
    for (const partComputing of Object.values(
      quoteComputed?.isComputing || {},
    )) {
      for (const methodComputing of Object.values(partComputing || {})) {
        if (methodComputing !== undefined) {
          return true;
        }
      }
    }
    return false;
  }, [quoteComputed]);

  // get expedite data
  // TODO: why is this an error if undefined?
  // const duration = quoteComputed?.price?.total?.duration;
  // const expedite = shop?.options?.expedite; //?.[expediteId || ''];

  // can we actually check out now
  // TODO: do we need all of these checks?
  const price = quoteComputed?.price?.total;
  const canCheckout = price !== undefined && !isNaN(price) && price > 0;

  const shopQuery = useShopQuery(shopId);
  const shop = shopQuery.data;

  return (
    <div className="checkout-area">
      <div className="row">
        <div className="col-lg mx-3">
          <ErrorBoundary>
            <h5>Shipping Address</h5>
            <EditableAddressBlock
              onChange={(address) => onAddressUpdate({ quoteId, address })}
            />
          </ErrorBoundary>
        </div>
        <div className="col-lg mx-3">
          <ErrorBoundary>
            <h5>Expedite Optimization</h5>
            <ExpediteSlider
              expediteId={expediteId}
              expediteValues={shop?.options?.expedite?.values}
              onExpediteChange={onExpediteUpdate}
            />
          </ErrorBoundary>
        </div>
        <div className="col-lg mx-3">
          <ErrorBoundary>
            <h5>Discount Code</h5>
            <DiscountTextBox
              discountId={quoteOptions?.discountId || ''}
              isDiscounting={!!quoteComputed?.isDiscounting}
              onDiscountChange={(discountId) =>
                onDiscountUpdate({ quoteId, shopId, discountId })
              }
              discount={quoteComputed?.discount}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col ">
          <OrderCreateButton
            quoteId={quoteId}
            disabled={isComputingPrice || !canCheckout}
          />
          <OrderEmailButton
            isEmphasized={!canCheckout}
            quoteId={quoteId}
            disabled={isComputingPrice}
          />
        </div>
      </div>
    </div>
  );
};
