import { useState } from 'react';

import { ResponseError, callAuthenticated, statusBad } from 'app/src/api';

import { TError, TFetcherParams, TFetcherReturn } from '../types';

import config_public from 'config/src/config-public.json';

const baseUri = `${config_public?.kerfed?.api_url || 'api'}/v1`;

/**
 * T = Response Type
 * V = Request Type
 */
export const fetcher = <T, V>({
  uri,
  variables,
  onCompleted,
  onError,
  onFinnaly,
}: TFetcherParams<T, V>): TFetcherReturn<T, V> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TError>();
  const [data, setData] = useState<T>({} as T);

  const handleFetch = async (props: TFetcherParams<T, V>) => {
    setLoading(true);

    let requestedUri = props.uri || uri || '';
    const pathParams =
      props.variables?.options?.pathParams || variables?.options?.pathParams;

    if (pathParams) {
      for (const key in pathParams) {
        requestedUri = requestedUri.replace(key, pathParams[key]);
      }
    }

    try {
      const res = await callAuthenticated({
        uri: `${baseUri}/${requestedUri}`,
        method:
          props.variables?.options?.method ||
          variables?.options?.method ||
          'POST',
        params: props.variables?.params || variables?.params,
        dateSince:
          props.variables?.options?.dateSince || variables?.options?.dateSince,
      });

      if (statusBad(res)) {
        throw await ResponseError(res);
      }

      const tmpData = (await res.json()) as T;
      setData(tmpData);
      if (onCompleted) onCompleted(tmpData);
      if (props?.onCompleted) props.onCompleted(tmpData);
    } catch (error) {
      setError(error as TError);
      if (onError) onError(error as TError);
      if (props?.onError) props.onError(error as TError);
    } finally {
      setLoading(false);
      if (onFinnaly) onFinnaly();
    }
  };

  return [
    handleFetch,
    {
      loading,
      data,
      error,
    },
  ];
};
