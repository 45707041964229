import { useQuery } from 'react-query';
import * as api from '../api';
import {
  KEYS_QUERY,
  USER_QUERY,
  USER_CURRENT_QUERY,
  QUOTES_QUERY,
  ORDERS_QUERY,
  SHOP_QUERY,
  SHOP_EDIT_QUERY,
} from './keys';

import { auth } from '../../../common/src/api/firebase';
import { signInAnonymously } from 'firebase/auth';

/**
 * By default react-query retries all failed attempts 3 times. This is
 * desirable if we had an unexpected error, but undesirable if we had
 * an explicit unauthorized (401) or not found (404) error thrown.
 *
 * This custom retry policy will retry all errors except 401 and 404.
 * https://react-query.tanstack.com/guides/query-retries
 */
export const retry = (failureCount: number, error: any): boolean => {
  if (failureCount >= 3 || [401, 404].includes(error?.name)) {
    return false;
  }
  return true;
};

export const useUserQuery = (userId: string) => {
  return useQuery([USER_QUERY, userId], () => api.userGet(userId), { retry });
};

export const useKeysQuery = (userId: string) => {
  return useQuery([KEYS_QUERY, userId], () => api.userKeyList(userId), {
    retry,
  });
};

export const useQuotesQuery = (offset = 0, shopId?: string) => {
  return useQuery(
    [QUOTES_QUERY, offset],
    () => api.quoteList(offset, 20, shopId),
    {
      retry,
      keepPreviousData: true,
    },
  );
};

export const useQuoteQuery = (quoteId: string, dateModified?: Date) => {
  // should query a quote ID, and poll it until `quote.status.isDone`
  // uploading a file should invalidate

  return useQuery(
    [quoteId, dateModified],
    () => api.quoteGet(quoteId, dateModified),
    {
      retry,
      keepPreviousData: true,
    },
  );
};

export const useOrdersQuery = (offset = 0, shopId?: string) => {
  return useQuery(
    [ORDERS_QUERY, offset],
    () => api.orderList(offset, 20, shopId),
    {
      retry,
      keepPreviousData: true,
    },
  );
};

export const useShopEditQuery = (shopId: string) => {
  return useQuery([SHOP_EDIT_QUERY, shopId], () => api.shopEditGet(shopId), {
    retry,
  });
};

export const useShopQuery = (shopId: string) => {
  return useQuery([SHOP_QUERY, shopId], () => api.shopGet(shopId), { retry });
};

/**
 * Get the current `User` for the client.
 *
 * Calls must be made using `user.accessToken`
 *
 * @returns
 */
export const useUserCurrentQuery = () => {
  const currentUser = async () => {
    if (auth?.currentUser) {
      return auth?.currentUser;
    }
    return signInAnonymously(auth).then((cred) => {
      return cred.user;
    });
  };
  return useQuery([], async () => await currentUser(), {
    retry,
  });
};
