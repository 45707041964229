import { validateEmail } from '../../../common/src/utils';
import React, { useState } from 'react';

import { useUserQuery } from '../query/queries';
import { useUserMutation } from '../query/mutations';

import 'bootstrap/js/dist/alert';

type Props = {
  shopId?: string;
  userId?: string;
};

const EmailAsk = ({ userId, shopId }) => {
  const [isOpen, setOpen] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [email, setEmail] = useState('');
  const [isValidEmail, setValid] = useState(true);

  const { isLoading, data } = useUserQuery(userId);
  const [createUserMutation] = useUserMutation(userId);

  const submitEmail = async () => {
    /**
     * Submit the email from the form to our cloud function
     */
    // only try to actually set the email if it passes our validator

    if (userId && validateEmail(email)) {
      // call our cloud function to update userInfo
      setSaving(true);
      await createUserMutation({ email });
      setSaving(false);
    }

    setOpen(false);
  };

  const isSubmittable = email.length > 0 && isValidEmail;

  const onChange = (event: any) => {
    // update the state with whatever they typed
    setEmail(event.target.value);
    setValid(validateEmail(event.target.value));
  };

  const onKeyPress = async (event) => {
    // if they press Enter call the submit function
    if (event.key === 'Enter' && isSubmittable) {
      await submitEmail();
    }
  };

  // ask if we are explictly open, not loading, and user has no email
  return isOpen && !isLoading && !data?.email ? (
    <div
      className="email-ask alert alert-primary alert-dismissible fade show"
      role="alert"
    >
      <h2 className="alert-heading">Email Not Found!</h2>
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <p>
        You don't need an account, but if you update your email here it will let
        us follow up when we manually review the automatic quote.
      </p>

      <div className="input-group mb-3">
        <input
          type="text"
          className={`form-control${isValidEmail ? '' : ' input-error'}`}
          placeholder="user@company.com"
          aria-label="Recipient's email"
          aria-describedby="button-addon2"
          value={email}
          autoFocus={true}
          autoComplete="email"
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
        {isSaving ? (
          <div className="input-group-text">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <button
            className={`btn btn-${
              isSubmittable ? 'primary' : 'outline-secondary'
            }`}
            type="button"
            onClick={submitEmail}
            disabled={!isSubmittable || isLoading}
            id="button-addon2"
          >
            Save
          </button>
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default EmailAsk;
