import React from 'react';
import { Money } from '@kerfed/client';

import SafePrice from '../../SafePrice';

interface OptionalPriceProps {
  enabled?: boolean;
  price?: Money | undefined;
}

const OptionalPrice = ({ enabled = true, price }: OptionalPriceProps) => {
  if (!enabled) return <span>-</span>;

  return (
    <SafePrice
      price={price}
      text="No Quote"
      message="One or more part options required to compute this price are missing or invalid. Either finish selection of options, or get a manual quote via email."
    />
  );
};

export default OptionalPrice;
