import { RestShop } from '@kerfed/client';

import { fetcher } from '../utils/fetcher';

import { TDefaultApiParams } from '../types';

export const useAPIGetShop = ({
  onCompleted,
  onError,
}: TDefaultApiParams<RestShop>) => {
  const [onGetShop, { loading }] = fetcher<RestShop, any>({
    uri: `shops/shopId`,
    variables: {
      options: { method: 'GET' },
    },
    onCompleted,
    onError,
  });

  return { onGetShop, loading };
};
