import { generateId } from '../../../common/src/utils';
import React, { useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  AiOutlineCloudUpload,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from 'react-icons/ai';

import { uploadFile } from '../api';

type FileUpload = {
  name: string;
  loaded: number;
  total: number;
  success?: boolean;
  error?: Error;
};

const Upload: React.FC<{ upload: FileUpload }> = ({
  upload: { name, loaded, total, success, error },
}) => (
  <div>
    <hr />
    <h2>
      {name}
      {success && <AiFillCheckCircle style={{ color: 'green' }} />}
      {error && <AiFillCloseCircle style={{ color: 'red' }} />}
    </h2>
    <div className="progress">
      <div
        className="progress-bar progress-bar-striped"
        role="progressbar"
        style={{
          width: `${success || error ? 100.0 : (loaded / total) * 100.0}%`,
        }}
        aria-valuenow={success || error ? 100.0 : (loaded / total) * 100.0}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  </div>
);
type Props = {
  onUploads?: (paths: string[]) => Promise<any>;
  sparse?: boolean;
};

export const FileUploader: React.FC<Props> = ({ onUploads, sparse }) => {
  const [uploads, setUploads] = useState<{ [key: string]: FileUpload }>({});

  const onDropAccepted = useMemo(
    () => async (files: File[]) => {
      // Upload a new file to the user's upload directory and make an API call to
      // add this file to a quote.
      const uploadTasks = files.map(async (file) => {
        const fileId = generateId();

        // Create a helper to update progress for this file.
        const setProgress = (progress) =>
          setUploads((newUploads) => ({
            ...newUploads,
            [fileId]: {
              ...progress,
              name: file.name,
            },
          }));

        try {
          // Try to upload a file and report progress along the way.
          setProgress({ loaded: 0, total: file.size });
          const uploadId = await uploadFile(file, (loaded, total) => {
            setProgress({ loaded, total });
          });
          setProgress({
            loaded: file.size,
            total: file.size,
            success: !!uploadId,
          });
          return { uploadId, fileId };
        } catch (error) {
          // If the upload fails with an error, mark and report it.
          console.error(error);
          setProgress({ success: false, error });
          return { fileId };
        }
      });

      // Process a list of only the successful uploads.
      // This filter removes all upload IDs with falsey values (i.e. undefined or empty)
      const uploadResults = await Promise.all(uploadTasks);

      // Remove the successfully uploaded files from the list.
      setUploads((prevUploads) => {
        const newUploads = { ...prevUploads };
        for (const { fileId } of uploadResults.filter((v) => v?.uploadId)) {
          delete newUploads[fileId];
        }
        return newUploads;
      });

      // If handler is registered, pass it the successful IDs.
      const uploadIds = uploadResults
        .map((v) => v?.uploadId || '')
        .filter(Boolean);
      if (onUploads) {
        await onUploads(uploadIds);
      }
    },
    [onUploads, setUploads],
  );

  return (
    <Dropzone
      className="NonSelectable"
      onDropAccepted={(files) => onDropAccepted(files)}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <div>
            <input {...getInputProps()} />
            {sparse ? (
              Object.keys(uploads).length > 0 ? (
                <div className="spinner-border my-2" role="status" />
              ) : (
                <p>Upload STEP, 3DXML, DXF, STL, etc.</p>
              )
            ) : (
              <div>
                <AiOutlineCloudUpload
                  style={{ width: '5em', height: 'auto' }}
                />
                <br />
                <p>Click or drag and drop files here to upload.</p>
                <p>
                  <strong>
                    Most CAD:
                    <br />
                  </strong>
                  File {String.fromCharCode(0x2192)} Save As{' '}
                  {String.fromCharCode(0x2192)} <strong>STEP</strong>
                </p>
                <p>
                  <strong>Supported formats:</strong>
                  <br />
                  STEP, 3DXML, DXF, 3MF, GLTF, DXF, DWG, PLY, STL, OBJ, AI, CDR
                </p>
                {Object.keys(uploads).length > 0 && (
                  <div className="container-md">
                    <ul>
                      {Object.entries(uploads).map(([fileId, upload]) => (
                        <Upload key={fileId} upload={upload} />
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default FileUploader;
