import { TFabricationMethod } from '@kerfed/types';

export const PROCESS_OPTIONS = [
  {
    key: 'flat',
    value: 'flat',
    text: 'Waterjet / Laser',
    description:
      "CNC lasers or waterjets can produce even large (5'x10') flat parts very quickly and accurately (typically 0.005\" or better).",
  },
  {
    key: 'bent',
    value: 'bent',
    text: 'CNC Bent',
    description:
      'CNC press brakes can take a laser or waterjet blank and form it with cylindrical bends into complex boxes, brackets, and other parts. ',
  },
  {
    key: 'roll',
    value: 'roll',
    text: 'CNC Roll',
    description:
      'CNC slip rolling can take a laser or waterjet blank of material up to 0.25" thick and roll it into a cylinder.',
  },
  {
    key: 'turn',
    value: 'turn',
    text: 'CNC Lathe',
    description:
      'CNC lathes such as the Haas ST15 or Mazack QT 200 can produce radially symmetric parts very quickly.',
  },
  {
    key: 'mill',
    value: 'mill',
    text: 'CNC Mill',
    description:
      'Conventional CNC milling can produce complex geometry as a series of extruded cuts using machines such as the Haas VF2SS or the Fanuc Robodrill.',
  },

  {
    key: 'cots',
    value: 'cots',
    text: 'COTS',
    description:
      'The part is commercially available and has been identified from a geometric hash.',
  },
  {
    key: 'add',
    value: 'add',
    text: '3D Print',
    description:
      'Additive techniques such as FDM and SLS can produce intricate geometries inexpensively.',
  },
  {
    key: 'manual',
    value: 'manual',
    text: 'Manual Quote',
    description: 'Have the part quoted manually.',
  },
];

export const CATEGORY_ORDER = ['material', 'thickness', 'finish', 'color'];

export const MAP_METHOD: Record<TFabricationMethod, string> = {
  FABRICATION_METHOD_ADD: 'add',
  FABRICATION_METHOD_BENT: 'bent',
  FABRICATION_METHOD_COTS: 'cots',
  FABRICATION_METHOD_FLAT: 'flat',
  FABRICATION_METHOD_MANUAL: 'manual',
  FABRICATION_METHOD_MILL: 'mill',
  FABRICATION_METHOD_ROLL: 'roll',
  FABRICATION_METHOD_TURN: 'turn',
  FABRICATION_METHOD_UNSPECIFIED: 'unspedified',
};

/**
 * add: "FABRICATION_METHOD_ADD"
 */
export const MAP_METHOD_ENUM: Record<
  keyof typeof MAP_METHOD,
  TFabricationMethod
> = {
  FABRICATION_METHOD_ADD: 'FABRICATION_METHOD_ADD',
  FABRICATION_METHOD_BENT: 'FABRICATION_METHOD_BENT',
  FABRICATION_METHOD_COTS: 'FABRICATION_METHOD_COTS',
  FABRICATION_METHOD_FLAT: 'FABRICATION_METHOD_FLAT',
  FABRICATION_METHOD_MANUAL: 'FABRICATION_METHOD_MANUAL',
  FABRICATION_METHOD_MILL: 'FABRICATION_METHOD_MILL',
  FABRICATION_METHOD_ROLL: 'FABRICATION_METHOD_ROLL',
  FABRICATION_METHOD_TURN: 'FABRICATION_METHOD_TURN',
  FABRICATION_METHOD_UNSPECIFIED: 'FABRICATION_METHOD_UNSPECIFIED',
};

export const INITIAL_BACKOFF_TIME_MS = 3000;
