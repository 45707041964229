import React, { useMemo } from 'react';
import { htmlToUnicode } from '@kerfed/common/utils';
import { PartConfiguration, Postprocess } from '@kerfed/client';

interface EditablePostOptionProps {
  posts: Postprocess[];
  config?: PartConfiguration | null;
  onChange: (newSelectedPostId: string) => void;
}

const EditablePostOption = ({
  posts,
  config,
  onChange,
}: EditablePostOptionProps) => {
  const usedGroups = useMemo(() => {
    if (!config?.posts?.length) return [];
    return posts
      .filter((post) => post?.post_id && config?.posts?.includes(post.post_id))
      .map((post) => post.group);
  }, [posts, config?.posts]);

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <form className="part-config-post">
      {posts.map((post) => (
        <div key={`check-${post.post_id}`} className="form-check">
          <input
            id={`radio-post-${post.post_id}`}
            className="form-check-input"
            type="checkbox"
            name={post?.post_id || ''}
            disabled={
              !post.post_id ||
              (!config?.posts?.includes(post.post_id) &&
                usedGroups.includes(post.group))
            }
            checked={!!post?.post_id && config?.posts?.includes(post.post_id)}
            value={post.post_id || ''}
            onChange={handleSelect}
          />
          <label
            className="form-check-label"
            htmlFor={`radio-post-${post.post_id}`}
          >
            {htmlToUnicode(
              `${post?.group ? `${post.group}: ` : ''}${post.description}`,
            )}
          </label>
        </div>
      ))}
    </form>
  );
};

export default EditablePostOption;
