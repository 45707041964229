import React, { useMemo } from 'react';
import { CotsFabrication } from '@kerfed/client';

import { EditablePartProps } from './types';

interface EditableCotsPart extends EditablePartProps {
  data: CotsFabrication;
}

const EditableCotsPart = ({
  partId,
  data,
  config,
  onChangePartOrderData,
}: EditableCotsPart) => {
  const handleChangePart = (cotsId: string) => {
    if (!partId || !config || !data.defaults) return;
    const defaultConfig = config || data.defaults;

    onChangePartOrderData(partId, {
      ...defaultConfig,
      cots_id: cotsId,
    });
  };

  const selectedText = useMemo(() => {
    const defaultCots = config?.cots_id || data?.defaults?.cots_id;

    return (
      data?.matches?.find((match) => match.cots_id === defaultCots)?.title ||
      'Select Part'
    );
  }, [data?.matches, data?.defaults?.cots_id, config?.cots_id]);

  return (
    <div className="pb-4">
      <div className="grid">
        <div className="row">
          <div className="col-md-6">
            <div className="dropdown">
              <button
                id="dropdownPartNumber"
                className="btn btn-outline-primary dropdown-toggle my-1 w-100"
                type="button"
                aria-placeholder="Select Part Number"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedText}
              </button>

              <ul
                className="dropdown-menu w-100"
                aria-labelledby="dropdownPartNumber"
              >
                {!!data?.matches?.length &&
                  data.matches.map((match) => (
                    <li key={`process-select-${match.cots_id}`}>
                      <a
                        style={{ cursor: 'pointer' }}
                        className="dropdown-item"
                        onClick={() => handleChangePart(match?.cots_id || '')}
                      >
                        {match.title || match.cots_id}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableCotsPart;
