import React from 'react';
import Geosuggest from '@ubilabs/react-geosuggest';
import { Address } from '../../../common/src/schemas/fields';
import { OnChangeCallback } from './callbacks';

import '../styles/Geosuggest.scss';

import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

import config_public from '../../../config/src/config-public.json';
const API_KEY = config_public?.google?.maps_api;

type Props = {
  onChange: OnChangeCallback;
  disabled?: boolean;
  error?: boolean;
};

/**
 * Convert a gmaps list of address components to our common address schema.
 *
 * @param gmaps result from google maps API
 * @returns Address
 */
const toAddress = (gmaps: any): Address => {
  // company?: string;
  // line1: string;
  // line2?: string;
  // city?: string;
  // state?: string;
  // zip: string;
  // country: string;

  if (!gmaps?.address_components) {
    return {} as Address;
  }

  // map from "geocoding API `types`" to our `Address` schema
  const mapping = {
    postal_code: 'zip',
    country: 'country',
    route: 'line1',
    locality: 'city',
    street_number: 'street_number',
    administrative_area_level_1: 'state',
  };

  // convert flat list of components into an object we can reference
  const mapped = {} as Address & { street_number?: string };
  for (let line of gmaps.address_components) {
    for (let type of line?.types) {
      // they include multiple "types" for each address ine
      if (mapping.hasOwnProperty(type) && line?.long_name) {
        mapped[mapping[type]] = line.long_name;
        break;
      }
    }
  }

  if (mapped?.street_number) {
    // gmaps has street and number as separate lines so combine here
    mapped.line1 = `${mapped.street_number} ${mapped.line1}`;
    delete mapped.street_number;
  }

  // the google place_id is a unique identifier
  mapped.place_id = gmaps.place_id;

  // we can use lattitude and longitude for fun
  mapped.latlon = [
    gmaps.geometry.location.lat(),
    gmaps.geometry.location.lng(),
  ];

  return mapped;
};

export default ({ onChange, disabled, error }: Props) => {
  const wrapChange = (event, _data) => {
    // convert the gmaps format address to a native one
    onChange(toAddress(event?.gmaps));
  };

  // check to see if we already have the google maps script loaded
  const [loaded, setLoaded] = useState(!!window?.google?.maps);
  // assign a callback for when the script loads
  // note this is referenced in the maps URL
  window.onGoogleMap = () => setLoaded(true);

  return (
    <div>
      {!loaded && (
        <Helmet>
          <script
            async={true}
            defer={true}
            type="text/javascript"
            src={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=onGoogleMap`}
          />
        </Helmet>
      )}
      {loaded ? (
        <Geosuggest
          inputClassName={`${error ? 'input-error' : ''}`}
          placeholder="Address"
          disabled={disabled}
          onSuggestSelect={wrapChange}
        />
      ) : (
        <span className="spinner-border" role="status" aria-hidden="true" />
      )}
    </div>
  );
};
