import React from 'react';
import { Link } from 'react-router-dom';
import { QuoteUploader } from '../components';
import '../styles/Home.scss';

export default () => (
  <div className="container-fluid">
    <div className="grid landing">
      <div className="row">
        <div className="col col-lg-7 left-panel">
          <div className="blurb">
            <h1>Real advanced manufacturing.</h1>
            <h1>Right now.</h1>
            <p>
              The Kerfed Intelligent Manufacturing Engine is a fabrication
              pipeline that uses geometric analysis and directed acyclic graph
              evaluation to allow mechanical systems to be fabricated in
              quantity one or one million in exactly the same way, resulting in
              an average per-part cost roughly half that of other digital
              fabrication providers.
            </p>
          </div>
          <div className="lower-panel">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  className="active"
                />
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                />
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                />
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="3"
                />
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-75"
                    src="/static/images/step1.png"
                    alt="First slide"
                  />
                  <h5>Automation Is Cheaper</h5>
                  <p>
                    Fully automated zero-touch processing means shorter lead
                    times and quantity-one part prices substantially less than
                    competitors. Human involvement represents a process failure.
                  </p>
                </div>

                <div className="carousel-item">
                  <img
                    className="d-block w-75"
                    src="/static/images/step1.png"
                    alt="First slide"
                  />
                  <h5>Radical Transparency</h5>
                  <p>
                    Cost structure, origin location, part artifacts, and
                    supplier for every single component is exposed at every
                    step, and an append-only transaction log lets you figure out
                    what is happening with every part.
                  </p>
                </div>

                <div className="carousel-item">
                  <img
                    className="d-block w-75"
                    src="/static/images/step1.png"
                    alt="First slide"
                  />
                  <h5>Zero-Touch CAM</h5>
                  <p>
                    We generate G-code with a compute shader on an Nvidia Tesla
                    to calculate offset volumes in just a few seconds; view the
                    cut part estimate and fixture-tooling plan as part of a
                    quote, or download and run them (at your own risk :)
                  </p>
                </div>

                <div className="carousel-item">
                  <img
                    className="d-block w-75"
                    src="/static/images/step1.png"
                    alt="Directed acyclic graph"
                  />
                  <h5>The future is DAGS</h5>
                  <p>
                    We treat every manufacturing step, from geometric analysis
                    to bugging a supplier via an email, as a node in a Directed
                    Acyclic Graph: this allows us to apply powerful modern DAG
                    evaluation engines pioneered at Uber and other companies to
                    physical execution.
                  </p>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true">
                  {' '}
                  -{' '}
                </span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true">
                  {' '}
                  &gt;{' '}
                </span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col col-md-5 right-panel">
          <div className="upload-block">
            <h5>Upload An Assembly</h5>
            <QuoteUploader shopId="kerfed" sparse={true} />
            <p>New to Kerfed IME? </p>
            <Link to="help">Check out our tutorial.</Link>
          </div>
        </div>
      </div>
      <div className="row m-5">
        <div className="card col-md">
          <img className="img-fluid" src="/static/images/step1.png" />
          <div className="card-body">
            <div className="card-title align-center">
              <h2>Step 1</h2>
            </div>
            <h5 className="card-text">
              Upload a 2D or 3D assembly or part from Solidworks or other CAD
              system.
            </h5>
          </div>
        </div>
        <div className="card col-md mx-2">
          <img className="img-fluid" src="/static/images/step2.svg" />
          <div className="card-body">
            <div className="card-title align-center">
              <h2>Step 2</h2>
            </div>
            <h5 className="card-text">
              The Kerfed Engine analyzes the geometry of every part in the
              assembly and quotes in seconds.
            </h5>
          </div>
        </div>
        <div className="card col-md">
          <img className="img-fluid" src="/static/images/step3.png" />
          <div className="card-body">
            <div className="card-title align-center">
              <h2>Step 3</h2>
            </div>
            <h5 className="card-text">
              Ordered parts are immediately released to manufacturing with
              generated cut-ready files.
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
);
