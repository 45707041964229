import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import all of bootstrap plus our overrides
import './styles/overrides.scss';

// import custom fonts
import './fonts/fonts.scss';
// import default custom styles
import './styles/index.scss';

import 'bootstrap/js/dist/carousel.js';
import 'bootstrap/js/dist/collapse.js';

import App from './App';
import { createRoot } from 'react-dom/client';

import config_public from '../../config/src/config-public.json';
// Configure top-level sentry.io logging
import * as Sentry from '@sentry/browser';
// Use a separate sentry route for frontend
if (config_public?.sentry?.frontend) {
  Sentry.init({ dsn: config_public?.sentry?.frontend });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
);
